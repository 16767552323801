export enum Microservices {
  Core = 'core',
  Auth = 'auth',
}

export enum Sockets {
  Document = 'core@Document',
  OrderDocument = 'core@OrderDocument',

  GeneralOrder = 'core@GeneralOrder',
  Order = 'core@Order',
}

export enum APIModels {
  User = 'User',
  Farmer = 'Farmer',
  Customer = 'Customer',
  Company = 'Company',
  FarmerProduct = 'FarmerProduct',
  Product = 'Product',
  Address = 'Address',
  Order = 'Order',
  GeneralOrder = 'GeneralOrder',
  OrderPosition = 'OrderPosition',
  Notification = 'Notification',
  Contact = 'Contact',
  Category = 'ProductCategory',
  ProductCategoryOptimized = 'ProductCategoryOptimized',
  CompanyDocument = 'CompanyDocument',
  Document = 'Document',
  Partner = 'Partner',
  Dadata = 'Dadata',
  Cart = 'Cart',
  Token = 'Token',
  ProfileDocument = 'ProfileDocument',
  OrderDocument = 'OrderDocument',
  PartnerDocument = 'PartnerDocument',
  PriceRequest = 'PriceRequest',
  PriceAnswer = 'PriceAnswer',
  ProductRequest = 'ProductRequest',
  Recipient = 'Recipient',
  ProductAntTree = 'ProductAntTree',
  RankedFarmer = 'RankedFarmer',
  ProductAnswer = 'ProductAnswer',
  AnswerPriceOption = 'AnswerPriceOption',
  SpecialPrice = 'SpecialPrice',
  PriceOption = 'PriceOption',
  AnswerSpecialPrice = 'AnswerSpecialPrice',
  MyPartner = 'MyPartner',
  Reduction = 'Reduction',
  ReductionWithBetter = 'ReductionWithBetter',
  ReductionProductAnswer = 'ReductionProductAnswer',
  ReductionParticipant = 'ReductionParticipant',
  ReductionProductRequest = 'ReductionProductRequest',
  ReductionSelectedRecipient = 'ReductionSelectedRecipient',
  Auction = 'Auction',
  AuctionWithBetter = 'AuctionWithBetter',
  AuctionProductAnswer = 'AuctionProductAnswer',
  AuctionParticipant = 'AuctionParticipant',
  AuctionProductRequest = 'AuctionProductRequest',
  AuctionSelectedRecipient = 'AuctionSelectedRecipient',
}

export enum Roles {
  Farmer = 'farmer',
  Customer = 'customer',
  Admin = 'admin',
  'user.confirmed' = 'user.confirmed',
  'farmer.no_personal_info' = 'farmer.no_personal_info',
  'customer.no_personal_info' = 'customer.no_personal_info',
}

export enum AccountTabs {
  AccountDetails = 'Данные аккаунта',
  MyCompany = 'Моя компания',
  AddressList = 'Список адресов',
  EmployeeList = 'Список сотрудников',
  ContactPersons = 'Контактные лица',
  AgreementForContractors = 'Договор для контрагентов',
  SignerData = 'Данные подписанта',
  LegalInformation = 'Юридические данные',
}

export enum AddressType {
  delivery = 'Адрес доставки',
  shipping = 'Адрес отгрузки',
}

/* Типы с бэка start */
export enum ProductType {
  Milk = 'Молоко',
  Beef = 'Говядина',
  Pork = 'Свинина',
  MarbleBeef = 'Мраморная говядина',
  Veal = 'Телятина',
  Potato = 'Картофель',
  Onion = 'Лук репчатый',
  Carrot = 'Морковь',
}

export enum ProductCategory {
  Meat = 'Мясо',
  Milk = 'Молочные продукты',
  Veggies = 'Овощи',
}

export enum FatType {
  UpTo1 = 'до 1 %',
  OneTwo = '1-2 %',
  TwoAndHalf = '2-2.5 %',
  ThreeAndHalf = '3.4-3.5 %',
  FourSix = '4.6-6 %',
}

export enum PackingInLiters {
  One = '1 литр',
  OneAndHalf = '1.5 литра',
  Five = '5 литров',
  Ten = '10 литров',
}

export const packingCategories = [
  { name: '1 литр', value: PackingInLiters.One },
  { name: '1,5 литра', value: PackingInLiters.OneAndHalf },
  { name: '5 литров', value: PackingInLiters.Five },
  { name: '10 литров', value: PackingInLiters.Ten },
]

export const beefMeatCategories = [
  { name: 'Категория 1', value: 'Категория 1' },
  { name: 'Категория 2', value: 'Категория 2' },
]

export const porkMeatCategories = [
  { name: 'Категория 1', value: 'Категория 1' },
  { name: 'Категория 2', value: 'Категория 2' },
  { name: 'Категория 3', value: 'Категория 3' },
  { name: 'Категория 4', value: 'Категория 4' },
  { name: 'Категория 5', value: 'Категория 5' },
  { name: 'Категория 6', value: 'Категория 6' },
]

/* Типы с бэка end */

// хедеры для таблиц дл каждой категории
export const CategoryHeaders = {
  milk: {
    product: 'Товар',
    amount: 'Количество',
    price: 'Стоимость за единицу',
    packaging: 'Фасовка',
    min: 'Минимальная продажа',
  },
  beef: {
    cutting: 'Разделка',
    meatCategory: 'Категория мяса',
    freezing: 'Заморозка',
    amount: 'Количество',
    price: 'Стоимость за единицу',
    min: 'Минимальная продажа',
  },
  pork: {
    cutting: 'Разделка',
    meatCategory: 'Категория мяса',
    freezing: 'Заморозка',
    amount: 'Количество',
    price: 'Стоимость за единицу',
    min: 'Минимальная продажа',
  },
  marbleBeef: {
    cutting: 'Разделка',
    meatCategory: 'Категория мяса',
    freezing: 'Заморозка',
    amount: 'Количество',
    price: 'Стоимость за единицу',
    min: 'Минимальная продажа',
  },
  potato: {
    product: 'Товар',
    amount: 'Количество',
    price: 'Стоимость за единицу',
    packaging: 'Фасовка',
    min: 'Минимальная продажа',
    type: 'Назначение сорта',
    color: 'Цвет',
  },
}

export enum MeasurmentTypes {
  liquid = 'л',
  solid = 'кг',
}

export const beefPartsTopGrade = [
  'Огузок',
  'Кострец',
  'Филейная часть',
  'Грудная часть',
  'Оковалок',
  'Спинная часть',
]

export const beefPartsFirstGrade = [
  'Шея',
  'Пашина',
  'Плечевая часть',
  'Лопаточная часть',
]

export const beefPartsSecondGrade = [
  'Зарез',
  'Передняя голяшка',
  'Задняя голяшка',
]

export const porkGrade = [
  'Голова',
  'Шейка',
  'Корейка',
  'Карбонад',
  'Окорок',
  'Вырезка',
  'Ребрышки',
  'Грудинка',
  'Лопатка',
  'Ножка',
]

export enum CuttingType {
  Carcass = 'Туша',
  HalfCarcass = 'Полутуша',
  Cut = 'Разруб',
}

export enum PasteurizationType {
  Pasteurized = 'Пастеризованное',
  Unpasteurized = 'Непастеризованное',
}

export enum FreezingTypes {
  Frozen = 'Замороженная',
  Chilled = 'Охлажденная',
}

// Опции для выбора товара
export const milkOptions = [
  { name: 'Молоко', value: ProductType.Milk, category: ProductCategory.Milk },
]
export const meatOptions = [
  { name: 'Говядина', value: ProductType.Beef, category: ProductCategory.Meat },
  {
    name: 'Мраморная говядина',
    value: ProductType.MarbleBeef,
    category: ProductCategory.Meat,
  },
  { name: 'Телятина', value: ProductType.Veal, category: ProductCategory.Meat },
  { name: 'Свинина', value: ProductType.Pork, category: ProductCategory.Meat },
]

export const vegetablesOptions = [
  {
    name: 'Картофель',
    value: ProductType.Potato,
    category: ProductCategory.Veggies,
  },
  {
    name: 'Лук репчатый',
    value: ProductType.Onion,
    category: ProductCategory.Veggies,
  },
  {
    name: 'Морковь',
    value: ProductType.Carrot,
    category: ProductCategory.Veggies,
  },
]

// Говядина разделка
export const meatCuttingOptionsEnum = [
  { name: 'Туша', value: CuttingType.Carcass },
  { name: 'Полутуша', value: CuttingType.HalfCarcass },
  { name: 'Разруб', value: CuttingType.Cut },
]

export const pasteurizationCategories = [
  { name: 'Пастеризованное', value: PasteurizationType.Pasteurized },
  { name: 'Непастеризованное', value: PasteurizationType.Unpasteurized },
]

export const fatCategories = [
  { name: 'до 1%', value: FatType.UpTo1 },
  { name: '1-2%', value: FatType.OneTwo },
  { name: '2-2,5%', value: FatType.TwoAndHalf },
  { name: '3,4-3,5%', value: FatType.ThreeAndHalf },
  { name: '4,6- 6%', value: FatType.FourSix },
]

export const freezingCategories = [
  { name: FreezingTypes.Frozen, value: FreezingTypes.Frozen },
  { name: FreezingTypes.Chilled, value: FreezingTypes.Chilled },
]

export const potatoTypeCategories = [
  { name: 'Столовый', value: 'столовый' },
  { name: 'Технический', value: 'технический' },
  { name: 'Кормовой', value: 'кормовой' },
]

export const potatoColorCategories = [
  { name: 'Белый', value: 'белый' },
  { name: 'Жёлтый', value: 'желтый' },
  { name: 'Красный', value: 'красный' },
  { name: 'Фиолетовый', value: 'фиолетовый' },
]

export const onionTypeCategories = [
  { name: 'Острый', value: 'острый' },
  { name: 'Полуострый', value: 'полуострый' },
  { name: 'Сладкий', value: 'сладкий' },
]

export const onionColorCategories = [
  { name: 'Белый', value: 'белый' },
  { name: 'Жёлтый', value: 'желтый' },
  { name: 'Фиолетовый', value: 'фиолетовый' },
]

export const carrotTypeCategories = [
  { name: 'Экстра', value: 'экстра' },
  { name: 'Первый', value: 'первый' },
  { name: 'Второй', value: 'второй' },
]

export enum GeneralOrderStatus {
  OnClearance = 'На оформлении',
  Changed = 'На подтверждении',
  Signed = 'Договор подписан фермером',
  OnDelivery = 'Готов к отгрузке',
  DonePartly = 'Заказ выполнен частично',
  NotDone = 'Заказ не выполнен',
  Done = 'Заказ выполнен',
  Cancelled = 'Заказ отменен',
}

export enum OrderStatus {
  OnClearance = 'На оформлении',
  Changed = 'Заказ изменен',
  Signed = 'Договор подписан фермером',
  OnDelivery = 'Готов к отгрузке',

  DonePartly = 'Заказ выполнен частично',
  NotDone = 'Заказ не выполнен',
  Done = 'Заказ выполнен',
  Cancelled = 'Заказ отменен',
}
//todo удалить старые статусы
export enum NewGeneralOrderStatus {
  New = 'Новый заказ',
  SupplierContractSigned = 'Поставщик подписал договор',
  OrderInDelivery = 'Заказ на доставке',
  OrderPicking = 'Сбор заказа',
  ContractUnderSigning = 'Договор на подписании',
  SupplierModifiedOrder = 'Заказ изменен поставщиком',
  OrderCompleted = 'Заказ выполнен',
  PartialOrderCompleted = 'Заказ выполнен частично',
  OrderNotCompleted = 'Заказ не выполнен',
  SupplierCancelledOrder = 'Заказ отменен поставщиком',
  BuyerCancelledOrder = 'Заказ отменен покупателем',
}

export enum TypeOfBusiness {
  IP = 'ИП',
  OOO = 'ООО',
  KFH = 'КФХ',
}

export const isProductMeat = [
  ProductType.Beef,
  ProductType.MarbleBeef,
  ProductType.Veal,
  ProductType.Pork,
] as string[]

export const isProductVegetable = [
  ProductType.Carrot,
  ProductType.Potato,
  ProductType.Onion,
] as string[]

export enum DocumentTypes {
  PAS = 'PAS', // Договор при верификации
  PA = 'PA', // "Соглашение с платформой"
  AC = 'AC', //Соглашение с клиентом
  Others = 'others', //"Отгрузочные документы"
  Proxy = 'proxy', //"Доверенность"
  Passport = 'passport', //Паспорт
  Tin = 'tin', //Скан инн организации
}

export enum ProfileDocumentTypes {
  PAS = 'PAS', // "Системный договор с платформой"
  PA = 'PA', //Договор с платформой
  Proxy = 'proxy', //"Доверенность"
  Passport = 'passport', //Паспорт
  Tin = 'tin', //Скан инн организации
}

export enum CatalogTagsTypes {
  Delivery = 'delivery',
  SelfDelivery = 'self-delivery',
  WithVat = 'with-vat',
}

export enum SuggestionTypes {
  Organisation = 'suggestionOrg',
  Bank = 'suggestionBank',
}
export enum OrganisationStatus {
  ACTIVE = 'Действующая',
  LIQUIDATING = 'Ликвидируется',
  LIQUIDATED = 'Ликвидирована',
  BANKRUPT = 'Банкротство',
  REORGANIZING = 'В процессе присоединения к другому юрлицу, с последующей ликвидацией',
}

export enum BankStatus {
  ACTIVE = 'Действующий',
  LIQUIDATING = 'Ликвидируется',
  LIQUIDATED = 'Ликвидирован',
}

export enum ProfileStatus {
  Verified = 'Аккаунт верифицирован',
  Unverified = 'Ожидает проверки администратором',
  Verifying = 'Проверяем данные организации',
  NeedToVerify = 'Верифицируйте аккаунт',
}

export enum DocumentTypesTitle {
  proxy = 'Доверенность',
  passport = 'Паспорт подписанта',
  supplyContract = 'Шаблон договора поставки',
  tin = 'ИНН организации',
  PA = 'Договор с платформой',
}

export enum TagType {
  WHITE = 'white',
  GREEN = 'green',
  LIGHTGREEN = 'light-green',
  BLUE = 'blue',
  ORANGE = 'orange',
  RED = 'red',
}

export enum TooltipType {
  WHITE = 'white',
  GREEN = 'green',
  LIGHTGREEN = 'light-green',
  BLUE = 'blue',
  ORANGE = 'orange',
  RED = 'red',
}

export enum PartnerShipStatus {
  SIGNED_CUSTOMER = 'signed_customer',
  NOT_SIGNED = 'not_signed',
  SIGNED_FARMER = 'signed_farmer',
}

export enum ExclamationTagType {
  ORANGE = '#FA8C16',
  GRAY = '#718096',
  RED = '#F16063',
}

export enum IPartnershipStatus {
  signed_customer = 'signed_customer',
  signed_farmer = 'signed_farmer',
  not_signed = 'not_signed',
}

export enum PaletizingType {
  EURO = 'euro',
  STANDARD = 'standard',
}

export enum ERequestStatus {
  canceled = 'Отменен',
  waiting = 'Ожидание ответа',
  received = 'Вы ответили',
}

export const OptionStatuses = [
  { value: 'Новый заказ', label: 'Новый заказ' },
  { value: 'Поставщик подписал договор', label: 'Поставщик подписал договор' },
  { value: 'Заказ на доставке', label: 'Заказ на доставке' },
  { value: 'Сбор заказа', label: 'Сбор заказа' },
  { value: 'Договор на подписании', label: 'Договор на подписании' },
  { value: 'Заказ изменен поставщиком', label: 'Заказ изменен поставщиком' },
  { value: 'Заказ выполнен', label: 'Заказ выполнен' },
  { value: 'Заказ выполнен частично', label: 'Заказ выполнен частично' },
  { value: 'Заказ не выполнен', label: 'Заказ не выполнен' },
  { value: 'Заказ отменен поставщиком', label: 'Заказ отменен поставщиком' },
  { value: 'Заказ отменен покупателем', label: 'Заказ отменен покупателем' },
]
